<template>
    <v-container>
        <v-snackbar
            v-model="alert.show"
            :timeout="3000"
            :color="alert.type"
        >
            {{alert.text}}

            <template v-slot:action="{ attrs }">
                <v-btn
                    color="blue"
                    text
                    v-bind="attrs"
                    @click="alert.show = false"
                >
                    Fechar
                </v-btn>
            </template>
        </v-snackbar>
        <v-card>
            <v-card-title>
                {{title}}

                <v-spacer></v-spacer>

                <v-btn
                    color="indigo darken-1"
                    dark
                    fab
                    small
                    :to="{name: `${name}.read`}"
                >
                    <v-icon>
                        mdi-arrow-left
                    </v-icon>
                </v-btn>
                <v-btn
                    color="indigo darken-1"
                    dark
                    small
                    @click="showHistorySidebar"
                >
                    Abrir histórico
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-form ref="form" >
                    <slot name="body" >
                    </slot>
                </v-form>
            </v-card-text>
            <v-card-text>
                <slot name="table" >
                </slot>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    color="indigo darken-1"
                    dark
                   @click.prevent="save()"
                >
                    <v-icon>
                        mdi-save
                    </v-icon>

                    Salvar
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn
                    color="pink darken-1"
                    dark
                    @click.prevent="cancel()"
                >
                    <v-icon>
                        mdi-block
                    </v-icon>

                    Cancelar
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-overlay :value="overlay">
            <v-progress-circular
                size="100"
                color="indigo darken-1"
            >
            </v-progress-circular>
        </v-overlay>
    </v-container>
</template>

<script>
export default {
    name: 'templateFranchise',
    data(){
        return {
            alert: {
                show: false,
                type: 'indigo',
                text: ''
            },
            overlay: false
        }
    },
    props: {
        value: {
            type: Object,
            required: false,
            default(){
                return {}
            }
        },
        name: {
            type: String,
            required: true
        },

        title: {
            type: String,
            required: true
        },

        id: {
            type: Number,
            required: false,
            default: 0,
        }
    },
    methods: {
        showHistorySidebar() {
            this.$root.$emit('franchise-history-open-sidebar')
        },
        save(){

        },
        cancel(){

        }
    },
    computed: {
        isLoading(){
            return this.load
        }
    },
}
</script>