<template>
    <ui-franchise
        v-model="form"
        title="Nova compra de produto"
        name="franchise-purchase"
    >
        <template #body >
            <ui-crud
                v-model="form"
                :rows="rows"
            />
        </template>
        <template #table >
            <h2>Produtos do pedido</h2>
            <pre>
                {{
                    {
                        produto: 1
                    }
                }}
            </pre>
        </template>
    </ui-franchise>
</template>

<script>
import uiFranchise from '@/templates/franchise'
import {uiCrud} from '@/components/ui'
export default {
    name: 'vwFranchisePurchases',
    components: {
        uiFranchise,
        uiCrud
    },
    data(){
        return {
            form: {

            },
            rows: [
                [
                    {
                        name: 'descricao',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'Natureza da Operação',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 6,
                            lg: 3,
                            xl: 3
                        }
                    }, 
                    {
                        name: 'doc_type',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'Tipo do Documento',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 6,
                            lg: 3,
                            xl: 3
                        }
                    }, 
                    {
                        name: 'serie',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'Série',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 3,
                            lg: 2,
                            xl: 2
                        }
                    }, 
                    {
                        name: 'sub_serie',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'Sub Série',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 3,
                            lg: 2,
                            xl: 2
                        }
                    }, 
                                    {
                        name: 'nota_fiscal',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'Nota Fiscal',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 6,
                            lg: 2,
                            xl: 2
                        }
                    }, 
                ], [
                    {
                        name: 'id_pessoa_fornecedor',
                        value: '',
                        type: 'string',
                        field: 'autocomplete',
                        label: 'Fornecedor',
                        handle: 'default',
                        service: 'pessoas',
                        serviceParams: "id,nome/tipo_pessoa='FOR'",
                        optionText: 'nome',
                        options: [],
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 6,
                            lg: 4,
                            xl: 4
                        },
                    },
                    {
                        name: 'self_',
                        value: '',
                        type: 'string',
                        field: 'checkbox',
                        label: 'Eu sou o fornecedor?',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 6,
                            lg: 2,
                            xl: 2
                        }
                    },
                    {
                        name: 'uf',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'UF',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 4,
                            lg: 2,
                            xl: 1
                        }
                    },
                    {
                        name: 'cnpj',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'CNPJ Fornecedor',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 4,
                            lg: 2,
                            xl: 3
                        }
                    },
                    {
                        name: 'inscricao',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'Inscrição Fornecedor',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 4,
                            lg: 2,
                            xl: 2
                        }
                    },
                ]
            ]
        }
    }
}
</script>